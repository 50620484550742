<template>
    <v-container fluid class="p-3">
   <v-row>
     <v-col cols="12" xs="12" sm="3" md="12">
       <v-carousel
         cycle
         height="auto"
         hide-delimiter-background
         show-arrows-on-hover
         class="border-12"
       >
               <template v-slot:prev="{ on, attrs }">
               <v-btn
                   fab
                   small
                   color="primary"
                   v-bind="attrs"
                   v-on="on"
               >
                   <v-icon>
                   mdi-arrow-left-thick
                   </v-icon>
               </v-btn>
               </template>
               <template v-slot:next="{ on, attrs }">
               <v-btn
                   fab
                   small
                   color="primary"
                   v-bind="attrs"
                   v-on="on"
               >
                   <v-icon>
                   mdi-arrow-right-thick
                   </v-icon>
               </v-btn>
               </template>
               <v-carousel-item
               src="../../assets/banner/amerta.jpg"
               reverse-transition="fade-transition"
               transition="fade-transition"
               class="border-12"
               >
               <v-row
                   class="fill-height"
                   align="center"
                   justify="center"
               >
               </v-row>
               </v-carousel-item>
           </v-carousel>
           </v-col>
       </v-row>
       <v-row class="mt-10">
           <h5>Entity</h5>
           <v-slide-group
           show-arrows        
           :multiple="true"
           >
           <v-slide-item
               v-for="(item, i) in items"
               :key="i"
               class="mr-2 mt-2 mb-2 ml-2"
           >
               <v-card
               :color="item.color"
               dark
               >
               <div class="d-flex flex-no-wrap justify-space-between">
                   <div>
                   <v-card-title
                       class="text-h5"
                       v-text="item.title"
                   ></v-card-title>

                   <v-card-subtitle v-text="item.artist"></v-card-subtitle>

                   <!-- <v-card-actions>
                       <v-btn
                       v-if="item.artist === 'Ellie Goulding'"
                       class="ml-2 mt-3"
                       fab
                       icon
                       height="40px"
                       right
                       width="40px"
                       >
                       <v-icon>mdi-play</v-icon>
                       </v-btn>

                       <v-btn
                       v-else
                       class="ml-2 mt-5"
                       outlined
                       rounded
                       small
                       to="/admin"
                       >
                       Explore
                       </v-btn>
                   </v-card-actions> -->
                   </div>

                   <v-avatar
                   class="ma-3"
                   size="125"
                   tile
                   >
                   <v-img :src="item.src" class="rounded-l border-12"></v-img>
                   </v-avatar>
               </div>
               </v-card>
           </v-slide-item>
           </v-slide-group>
       </v-row>
   </v-container>
</template>
<script>
import {backendApi} from "../../backend-api/backend-api-sr"

export default {
   data() {
   return {
       snackbar: {
           color: null,
           icon: null,
           mode: null,
           position: "top",
           text: null,
           timeout: 7500,
           title: null,
           visible: false
       },
       slides: [
       {
           src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
       }
       ],
       colors: [
       'indigo',
       'warning',
       'pink darken-2',
       'red lighten-1',
       'deep-purple accent-4',
       ],
       items: [
       // {
       //   color: '#1F7087',
       //   src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
       //   title: 'Supermodel',
       //   artist: 'Foster the People',
       // },
       // {
       //   color: '#952175',
       //   src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
       //   title: 'Halcyon Days',
       //   artist: 'Ellie Goulding',
       // },
       ],
       model: null,
   }
   },
   mounted(){
   this.getDashboard()
   },
   methods:{
       async getDashboard(){
           const respData = await backendApi.fetchCore('/api/get_entity', null, false, false, false)
           console.log(respData.data.data);

           for (let i = 0; i < respData.data.data.length; i++) {
               this.items.push({
               color: '#1F7087',
               src: respData.data.data[i].url_image,
               title: respData.data.data[i].entity_id,
               artist: respData.data.data[i].description,
               link: respData.data.data[i].route_web
               })
           }
       },
   },
}
</script>